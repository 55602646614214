(function () {
    angular.module('PWAPoCApp').factory('authInterceptorService', authInterceptorService);

    authInterceptorService.$inject = ['$q', '$window', '$localForage'];

    function authInterceptorService($q, $window, $localForage) {
        return {
            request: request,
            responseError: responseError
        };

        function request(config) {
            var deferred = $q.defer();

            config.headers = config.headers || {};

            $localForage.getItem('authData').then(function (authData) {
                if (authData) {
                    //Readd this code after token based auth is finished
                    config.headers.Authorization = 'Bearer ' + authData.token;
                    config.headers.CustomerId = authData.customerId;
                }
                deferred.resolve(config);
            }, function () {
                deferred.reject();
            });

            return deferred.promise;
        }

        function responseError(response) {
            var deferred = $q.defer();

            if (response && response.status === 401 && $window.location.pathname.indexOf('login') === -1) {

                $localForage.removeItem('authData').then(function () {
                    $window.location.href = $window.location.origin + '/login';
                    deferred.resolve();
                }, function () {
                    $q.reject(response);
                    deferred.reject();
                });

            } else {
                $q.reject(response);
                deferred.reject();
            }

            return deferred.promise;
        }
    }
})();
