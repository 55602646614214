(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('receiptTemplateService', receiptTemplateService);

    receiptTemplateService.$inject = ['$http', 'serviceUrls', 'cacheService', 'locale'];

    function receiptTemplateService($http, serviceUrls, cacheService, locale) {

        var cacheKey = "receiptTemplate";
        var baseSpacing = 50;

        var service = {
            getReceiptTemplate: getReceiptTemplate,
            createPrintableReceipt: createPrintableReceipt
        };

        return service;

        function getReceiptTemplate(refresh) {
            return new Promise(resolve => {
                var query = refresh ? getServerReceiptTemplate() : cacheService.get(cacheKey);
                query
                    .then(template => {
                        resolve(template);
                    })
                    .catch(() => {
                        resolve(null);
                    });
            });
        }

        function getServerReceiptTemplate() {
            return new Promise((resolve, reject) => {
                $http.get(serviceUrls.receiptTemplate)
                    .then(response => {
                        if (response && response.data) {
                            return cacheService.set(cacheKey, response.data.template);
                        } else {
                            return Promise.reject();
                        }
                    }).then(() => {
                        resolve();
                    }).catch(() => {
                        reject();
                    });
            });
        }

        function createResult(data, errorMsg) {
            var result = {
                data,
                errorMsg
            };
            return result;
        }

        function createPrintableReceipt(template, userData) {
            var errorMsg;
            try {

                if (!template) {
                    return createResult(null, locale.error_template);
                }

                if (!userData) {
                    return createResult(null, locale.error_userData);
                }

                var resultLines;
                if (userData.routeStop) {
                    var routeStop = userData.routeStop;
                    var templateCommands = [];
                    if (routeStop) {
                        template = template.replace("MULTITEXT", "TEXT");
                        var templateLines = template.split("\r\n");

                        var templateHeaderLines = templateLines.slice(0, 2);
                        var templateBodyLines = templateLines.slice(2, templateLines.length - 2);
                        var templatePrintCommandLine = templateLines.slice(templateLines.length - 2, templateLines.length - 1);
                        var validControlWords = ["LEFT", "RIGHT", "CENTER"];

                        var j = 0;
                        for (var i = 0; i < templateBodyLines.length; i += 2) {
                            var controlWord = templateBodyLines[i];
                            var templateBodyLine = templateBodyLines[i + 1];
                            if (validControlWords.includes(controlWord)) {
                                var templateCommand = parseTemplateCommand(j, templateBodyLine, controlWord);
                                if (templateCommand) {
                                    templateCommands.push(templateCommand);
                                }
                                j++;
                            }
                        }

                        var replaceResult = replaceTemplateWords(templateCommands, userData);
                        if (replaceResult.errorMsg) {
                            return createResult(null, replaceResult.errorMsg);
                        }

                        var formattedTemplateCommandsResult = formatLongTemplateCommands(templateCommands);
                        if (formattedTemplateCommandsResult.errorMsg) {
                            return createResult(null, formattedTemplateCommandsResult.errorMsg);
                        }

                        var formattedTemplateCommands = formattedTemplateCommandsResult.data;
                        var alignResult = alignVerticalPositions(formattedTemplateCommands);
                        if (alignResult.errorMsg) {
                            return createResult(null, locale.error_invalid);
                        }

                        resultLines = [];
                        addAdjustedHeaderLines(resultLines, templateHeaderLines, formattedTemplateCommands);
                        _.forEach(formattedTemplateCommands, command => {
                            resultLines.push(command.controlWord);
                            resultLines.push(command.toStringLine());
                        });
                        resultLines.push(templatePrintCommandLine[0] + "\r\n");
                        if (!_.some(resultLines, rL => rL.includes('PRINT'))) {
                            return createResult(null, locale.error_template + " 2");
                        }
                    }
                }

                return createResult(resultLines ? resultLines.join("\r\n") : "", errorMsg);

            } catch (error) { // only for testing purposes
                return createResult(null, error);
            }
        }

        function addAdjustedHeaderLines(resultLines, templateHeader, formattedTemplateCommands) {
            var firstLineTokens = templateHeader[0].split(" ");
            firstLineTokens[4] = addAsNumber(_.maxBy(formattedTemplateCommands, 'sequence').y, 3 * baseSpacing);
            resultLines.push(firstLineTokens.join(" "));
            resultLines.push(templateHeader[1]);
        }

        function parseTemplateCommand(sequence, line, controlWord) {
            try {
                var tags = line.split(" ");

                var templateCommand = {
                    sequence: sequence,
                    part: -1,
                    command: tags[0],
                    offset: tags[1],
                    fontSize: tags[2],
                    x: tags[3],
                    y: tags[4],
                    text: tags.slice(5, tags.length).join(" "),
                    controlWord: controlWord
                }

                templateCommand.toStringLine = function () {
                    return this.command + " " + this.offset + " " + this.fontSize +
                        " " + this.x + " " + this.y + " " + this.text;
                }
                return templateCommand;
            } catch (error) {
                return null;
            }
        }

        function alignVerticalPositions(formattedTemplateCommands) {
            try {
                for (var i = 0; i < formattedTemplateCommands.length; i++) {
                    var command = formattedTemplateCommands[i];
                    if (command.part === 0) {
                        var j;
                        for (j = i; j < formattedTemplateCommands.length; j++) {//go through the later commands and increment vertical values
                            var nextCommand = formattedTemplateCommands[j];
                            if (nextCommand.sequence === command.sequence) {
                                nextCommand.y = addAsNumber(nextCommand.y, baseSpacing * (j - i)); //add 0*50 1*50 for parts
                            } else {
                                var min = _.minBy(_.filter(formattedTemplateCommands, c => c.sequence === command.sequence), 'part');
                                var max = _.maxBy(_.filter(formattedTemplateCommands, c => c.sequence === command.sequence), 'part');
                                var spacing = max.y - min.y;
                                nextCommand.y = addAsNumber(nextCommand.y, spacing);
                            }
                        }
                    }
                }
                return {
                    data: ""
                };
            } catch (err) {
                return {
                    errorMsg: "align vertical"
                };
            }

        }

        function addAsNumber(a, b) {
            return (Number(a) + Number(b)).toString();
        }

        function formatLongTemplateCommands(replacedTextTemplateCommands) {
            try {
                var maxLength = 35;
                var formattedTemplateCommands = [];

                _.forEach(replacedTextTemplateCommands, command => {
                    if (command.text.length > maxLength) {
                        var contentTextParts = command.text.split(' ');
                        var j = 0;
                        for (var i = 0; i < contentTextParts.length;) {
                            var parts = takeMaxAllowed(i, contentTextParts, maxLength);
                            if (!parts)
                                break;
                            i += parts.length;

                            var newPartCommand = _.cloneDeep(command);
                            newPartCommand.part = j++;
                            newPartCommand.text = parts.join(" ");
                            formattedTemplateCommands.push(newPartCommand);
                        }
                    } else {
                        formattedTemplateCommands.push(command);
                    }
                });
                return {
                    data: _.orderBy(formattedTemplateCommands, 'sequence')
                };
            } catch (error) {
                return {
                    errorMsg: locale.error_formatLongLines
                };
            }

        }

        function takeMaxAllowed(start, array, maxLength) {
            var returnStr = "";
            var i = start;

            if (i >= array.length) {
                return null;
            }

            if (i < array.length) {
                while (i < array.length && (returnStr + " " + array[i + 1]).length < maxLength) {
                    returnStr += " " + array[i];
                    i++;
                }

                return array.slice(start, i);
            }

            if (i === array.length - 1) {
                return array.slice(start, array.length);
            }

        }

        function replaceTemplateWords(templateCommands, userData) {
            try {
                var replacementRules = createReplacementRules(userData);
                _.forEach(templateCommands, command => {
                    var matchingRule = _.find(replacementRules, rule => command.text.includes(rule.key));
                    if (matchingRule) {
                        command.text = command.text.replace(matchingRule.key, matchingRule.value);
                    }
                });
                return {
                    errorMsg: null
                }
            } catch (error) {
                return {
                    errorMsg: locale.error_fillingTemplateData
                }
            }
        }

        function createReplacementRules(userData) {
            var routeStop = userData.routeStop;
            var unit = routeStop && routeStop.units && routeStop.units.length > 0 ? routeStop.units[0] : null;
            var containerId = unit ? unit.containerId : "-";
            var address = routeStop.address ? routeStop.address : "";
            var driverId = userData && userData.driver && userData.driver.driverId ? userData.driver.driverId : "";
            var driverName = userData && userData.driver && userData.driver.driverName ? userData.driver.driverName : "";
            var deviationText = routeStop.deviations && routeStop.deviations.length ? _.map(routeStop.deviations, d => d.deviation.text).join(", ") : "";
            var commentsText = routeStop.comments && routeStop.comments.length ? _.map(routeStop.comments, c => c.comment ? c.comment : "").join(", ") : "";
            var formattedTime = moment(routeStop.time, "YYYY-MM-DDTHH:mm:ss").format('DD/MM/YYYY HH:mm:ss');

            var replacementRules = [
                { key: "(phAnNummer)", value: containerId },
                { key: "(phAdresse)", value: address },
                { key: "(phTidspunkt)", value: formattedTime },
                { key: "(phNavnSjafor)", value: driverName },
                { key: "(phUtfortAv)", value: driverId },
                { key: "(phAvvik)##(phAvvik)", value: (" " + deviationText) },
                { key: "(phKommentarTekst)", value: commentsText },
            ];

            if (routeStop.dataButtons.length > 0) {
                replacementRules.push({ key: "(phKubikData1)", value: routeStop.dataButtons[0].value });
                replacementRules.push({ key: "(phKubikData1ButtonText)", value: routeStop.dataButtons[0].valueNO });
            }

            if (routeStop.dataButtons.length > 1) {
                replacementRules.push({ key: "(phData2)", value: routeStop.dataButtons[1].value });
                replacementRules.push({ key: "(phData2ButtonText)", value: routeStop.dataButtons[1].valueNO });
            }

            if (routeStop.dataButtons.length > 2) {
                replacementRules.push({ key: "(phData3)", value: routeStop.dataButtons[2].value });
                replacementRules.push({ key: "(phData3ButtonText)", value: routeStop.dataButtons[2].valueNO });
            }

            return replacementRules;
        }
    }
})();
