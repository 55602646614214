(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('bluetoothService', bluetoothService);

    bluetoothService.$inject = ['$q', '$rootScope', 'appSettings', 'settingsService'];

    function bluetoothService($q, $rootScope, appSettings, settingsService) {

        var bluetoothService = {
            getWeightSensor: getWeightSensor,
            startNotifications: startNotifications,
            stopNotifications: stopNotifications,
            attachBotekWeightUnitDevice: attachBotekWeightUnitDevice,
            attachZQ510Printer: attachZQ510Printer,
            getZQ510Printer: getZQ510Printer
        };

        return bluetoothService;

        function startNotifications(notificationHandler) {
            if ($rootScope.btDevice) {
                addNotificationHandler($rootScope.btDevice, notificationHandler);
            } else {
                attachBotekWeightUnitDevice().then(success => {
                    if (success) {
                        addNotificationHandler($rootScope.btDevice, notificationHandler);
                    };
                });
            }
        }

        function stopNotifications(notificationHandler) {
            if ($rootScope.btDevice) {
                $rootScope.btDevice.gatt.connect()
                    .then(server => {
                        return server.getPrimaryServices();
                    })
                    .then(services => {
                        return services[0].getCharacteristics();
                    })
                    .then(characteristics => {
                        if (characteristics.length > 0) {
                            var notifyCharacteristic = characteristics[0];
                            notifyCharacteristic.removeEventListener('characteristicvaluechanged',
                                notificationHandler);
                            notifyCharacteristic.stopNotifications();
                            console.log('notifications turned off');
                        }
                    }).catch(err => {
                        console.log('Error in bluetooth stopNotifications: ' + err);
                    });
            }

        }

        function addNotificationHandler(device, notificationHandler) {
            device.gatt.connect()
                .then(server => {
                    return server.getPrimaryServices();
                })
                .then(services => {
                    return services[0].getCharacteristics();
                })
                .then(characteristics => {
                    if (characteristics.length > 0) {
                        var notifyCharacteristic = characteristics[0];
                        notifyCharacteristic.addEventListener('characteristicvaluechanged',
                            notificationHandler);
                        notifyCharacteristic.startNotifications();
                        console.log('notifications turned on');
                    }

                })
                .catch(err => {
                    console.log('Error in bluetooth startNotifications: ' + err);
                });
        }

        function isiOSDevice() {
            var platform = navigator.platform;
            return platform === 'iPad' || platform === 'iPhone' || platform === 'iPod' || platform.includes('Mac');
        }

        function attachBotekWeightUnitDevice(forceScan) {
            var deferred = $q.defer();

            if (!isiOSDevice() && appSettings.bluetoothWeightEnabled) {
                if (forceScan) {
                    $rootScope.btDevice = null;
                }

                settingsService.getIsWeightUnitBotek().then(function (isWeightUnitBotek) {
                    $rootScope.isWeightUnitBotek = isWeightUnitBotek;
                    return settingsService.getBTAdapterName();
                }).then(btAdapterName => {
                    $rootScope.BTAdapterName = btAdapterName;
                    if ($rootScope.isWeightUnitBotek) {
                        //attach bluetooth device to rootScope
                        bluetoothService.getWeightSensor().then(device => {
                            if (device) {
                                deferred.resolve(true);
                            }
                            deferred.resolve(false);
                        });
                    } else {
                        deferred.resolve(false);
                    }
                });
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        }

        function getWeightSensor() {
            var deferred = $q.defer();

            var nameFilters = appSettings.bleNameFilters;
            if ($rootScope.BTAdapterName) {
                nameFilters.push({ name: $rootScope.BTAdapterName });
                nameFilters = _.uniqBy(nameFilters, 'name');
            }


            let options = {
                filters: nameFilters,
                optionalServices: appSettings.BLE232.SERVICE_UUIDs
            };

            if ($rootScope.btDevice) {
                deferred.resolve($rootScope.btDevice);
            } else {
                navigator.bluetooth.requestDevice(options)
                    .then(device => {
                        $rootScope.btDevice = device;
                        deferred.resolve(device);
                    })
                    .catch(error => {
                        deferred.resolve(null);
                    });
            }

            return deferred.promise;
        }

        function attachZQ510Printer(forceScan) {
            return new Promise(resolve => {
                if (!isiOSDevice()) {
                    if (forceScan) {
                        $rootScope.zq510Printer = null;
                    }

                    getZQ510Printer().then(device => {
                        if (device) {
                            resolve(true);
                        }
                        resolve(false);
                    });

                } else {
                    resolve(false);
                }
            });
        }

        function getZQ510Printer() {
            return new Promise(resolve => {
                settingsService.getBTPrinterNames()
                    .then(printerNames => {
                        if ($rootScope.printerDevice) {
                            resolve($rootScope.printerDevice);
                        } else {
                            let options;
                            if (printerNames) {
                                options = {
                                    filters: _.map(printerNames, function (printerName) {
                                        return { name: printerName };
                                    }),
                                    optionalServices: appSettings.ZQ510.SERVICE_UUIDs
                                };
                            } else {
                                options = {
                                    acceptAllDevices: true,
                                    optionalServices: appSettings.ZQ510.SERVICE_UUIDs
                                };
                            }

                            return navigator.bluetooth.requestDevice(options);
                        }
                    })
                    .then(device => {
                        $rootScope.printerDevice = device;
                        resolve(device);
                    })
                    .catch(error => {
                        resolve(null);
                    });;
            });
        }

    }
})();
