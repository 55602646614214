(function () {
    'use strict';

    angular.module('PWAPoCApp').controller('SupportController', supportController);

    supportController.$inject = ['$scope', '$kWindow'];

    function supportController($scope, $kWindow) {
        $scope.sendReport = sendReport;

        function sendReport() {
            $kWindow.open({
                options: {
                    modal: true,
                    title: 'Send rapport',
                    draggable: false,
                    movable: false,
                    resizable: false,
                    visible: false,
                    height: 250,
                    width: 350
                },
                templateUrl: 'app/support/send-report-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SendReportController'
            });
        }
    }
})();
