(function () {
    'use strict';

    angular.module('PWAPoCApp').factory('networkService', networkService);

    networkService.$inject = ['$q', '$http', 'serviceUrls'];

    function networkService($q, $http, serviceUrls) {
        var networkService = {
            isOnline: isOnline
        };

        return networkService;

        function isOnline() {
            var deferred = $q.defer();

            $http.get(serviceUrls.ping, { timeout: 2000 }).then(function() {
                deferred.resolve(true);
            }, function() {
                deferred.resolve(false);
            });

            return deferred.promise;
        }
    }
})();
